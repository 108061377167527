import React from "react";

export default class ChartExpert extends React.PureComponent {
  constructor(props) {
    super(props);
    this._ref = React.createRef();
    this.state = {
      symbols: [
        {
          "description": "",
          "proName": "OANDA:USDJPY"
        },
        {
          "description": "",
          "proName": "OANDA:GBPUSD"
        },
        {
          "description": "",
          "proName": "OANDA:EURUSD"
        },
        {
          "description": "",
          "proName": "OANDA:USDCHF"
        },
        {
          "description": "",
          "proName": "OANDA:GBPAUD"
        },
        {
          "description": "",
          "proName": "OANDA:GBPCHF"
        }
      ],
      showSymbolLogo: true,
      colorTheme: "dark",
      isTransparent: true,
      displayMode: "adaptive",
      locale: "en"
      };
    this.currentDOMId = "";
  }
  componentDidMount() {
    const script = document.createElement("script");
    script.id = `gg${this.state.type}`;
    this.currentDOMId = `gg${this.state.type}`;
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-ticker-tape.js";
    script.async = true;
    this._ref.current.appendChild(script);
    script.innerHTML = JSON.stringify(this.state);
  }
  render() {
    return (
      <div className="tradingview-widget-container" ref={this._ref}>
        <div className="tradingview-widget-container__widget"> </div>
      </div>
    );
  }
}
