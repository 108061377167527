import React from "react";
import PropTypes from "prop-types";
import { Link, graphql } from "gatsby";
import { motion } from "framer-motion";
import { InView } from "react-intersection-observer";
import Layout from "../components/Layout";
import { Helmet } from "react-helmet";
import ContactForm from "../pages/contact/form";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import StockGainerPersonal from "../components/StockGainerPersonal";
import ChartTwo from "../components/ChartTwo";
import ChartBandLight from "../components/ChartBandLight";
import ChartThreeLight from "../components/ChartThreeLight";

export const PrivacypolicyPageTemplate = ({
  image,
  title,
  heading,
  subheading,
  mainpitch,
  description,
  intro,
}) => (
  <div>
    <Helmet>
      <title>Swiftfox | Privacy Policy</title>
    </Helmet>
    <div className="hero-section faq-hero">
      <div className="hero-main">
        <div className="hero-main-content">
          <div className="section-item white-text">
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h1
                  ref={ref}
                  initial={{ opacity: 0 }}
                  animate={inView ? { opacity: 1 } : { opacity: 1 }}
                  transition={{ duration: 0.4, delay: 0.25, ease: "easeInOut" }}
                >
                  Privacy Policy
                </motion.h1>
              )}
            </InView>
            <InView threshold={0.1}>
              {({ ref, inView }) => (
                <motion.h4
                  ref={ref}
                  initial={{ opacity: 0 }}
                  animate={inView ? { opacity: 1 } : { opacity: 1 }}
                  transition={{ duration: 0.4, delay: 0.5, ease: "easeInOut" }}
                >
                  Welcome to Swiftfox. We respect your privacy and are committed
                  to protecting your personal data. This policy explains how we
                  collect, use, and share information about you when you visit
                  or use our services.{" "}
                </motion.h4>
              )}
            </InView>
          </div>
        </div>
      </div>
    </div>

    <div className="callout-section white-bg dark-text">
      <div className="callout-main">
        <div className="callout-main-content stack-items">          <p>

          Information We Collect:
          <br></br>
We collect information you provide directly, data related to your transactions, and data from third parties. This may include personal details, contact information, and financial data.
<br></br>
<br></br>
How We Use Your Information:
<br></br> 
Your information helps us to provide, improve, and personalise our services. We use it for customer support, compliance, and marketing, subject to your preferences.
<br></br>
Sharing of Information: 
We may share your information with service providers, regulatory authorities, and as part of business transfers, always ensuring protection and legal compliance.
<br></br>
<br></br>
Your Rights:
<br></br> 
You have rights over your personal data, including access, correction, and deletion. You can also object to or restrict certain processing activities.
<br></br>
<br></br>
Data Security:
<br></br> 
We implement robust measures to secure your data against unauthorised access, alteration, and loss.
<br></br>
<br></br>
Changes to This Policy:
<br></br> 
We may update this policy from time to time. We will notify you of significant changes and reflect the date of the latest update at the top of the policy.
<br></br>
<br></br>
Contact Us: 
<br></br>
If you have questions about this policy, please contact us at support@swiftfox.co.uk.
          </p>


        </div>





      </div>
    </div>
  </div>
);

PrivacypolicyPageTemplate.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  heading: PropTypes.string,
  subheading: PropTypes.string,
  mainpitch: PropTypes.object,
  description: PropTypes.string,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
};

const PrivacypolicyPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <PrivacypolicyPageTemplate
        image={frontmatter.image}
        title={frontmatter.title}
        heading={frontmatter.heading}
        subheading={frontmatter.subheading}
        mainpitch={frontmatter.mainpitch}
        description={frontmatter.description}
        intro={frontmatter.intro}
      />
    </Layout>
  );
};

PrivacypolicyPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default PrivacypolicyPage;

export const pageQuery = graphql`
  query PrivacypolicyPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        heading
        subheading
        mainpitch {
          title
          description
        }
        description
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            text
          }
          heading
          description
        }
      }
    }
  }
`;
