import React from "react";

export default class ChartTwo extends React.PureComponent {
  constructor(props) {
    super(props);
    this._ref = React.createRef();
    this.state = {
      width: "100%",
      height: "100%",
      colorTheme: "dark",
      isTransparent: true,
      currencies: [
        "EUR",
         "USD",
         "JPY",
         "GBP",
         "CHF",
         "AUD",
        ],
        locale: "en"
      };
    this.currentDOMId = "";
  }
  componentDidMount() {
    const script = document.createElement("script");
    script.id = `gg${this.state.type}`;
    this.currentDOMId = `gg${this.state.type}`;
    script.src =
      "https://s3.tradingview.com/external-embedding/embed-widget-forex-cross-rates.js";
    script.async = true;
    this._ref.current.appendChild(script);
    script.innerHTML = JSON.stringify(this.state);
  }
  render() {
    return (
      <div className="tradingview-widget-container" ref={this._ref}>
        <div className="tradingview-widget-container__widget"> </div>
      </div>
    );
  }
}
