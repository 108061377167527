import React from "react";
import { navigate } from "gatsby-link";

function encode(data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&");
}

export default class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isValidated: false, messageText : "Field is require."  };
  }

  handleChange = (e) => {
    e.target.parentElement.classList.remove("requiredBol")
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = (e) => {
    e.preventDefault();
    const form = e.target;

    this.state.isValidatedFalse = false;
   
    if ( this.state.name == null ||  this.state.name == '' ){
      this.name.parentElement.classList.add("requiredBol");
      this.name.focus();
      this.setState({ isValidatedFalse : true, messageText : "Name field is required." });
      return false;
    }
    else if ( this.state.email == null ||  this.state.email == '' ){
      this.email.parentElement.classList.add("requiredBol");
      this.email.focus();
      this.setState({ isValidatedFalse : true, messageText : "Email field is required." });
      return false;
    } else if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.state.email))){
      this.email.parentElement.classList.add("requiredBol");
      this.email.focus();
      this.setState({ isValidatedFalse : true, messageText : "Email is invalid." });
      return false;
    }
    else if ( this.state.phone == null ||  this.state.phone == ''){
      this.phone.parentElement.classList.add("requiredBol");
      this.phone.focus();
      this.setState({ isValidatedFalse : true, messageText : "Phone field is required." });
      return false;
    }
    else if ( this.state.message == null ||  this.state.message == ''){
      this.message.parentElement.classList.add("requiredBol");
      this.message.focus();
      this.setState({ isValidatedFalse : true, messageText : "message field is required." });
      return false;
    }

    delete this.state.isValidated;
    delete this.state.messageText;
    
    fetch("https://getform.io/f/e300e446-b4c0-49f6-baae-8279bb0c49f9", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...this.state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch((error) => alert(error));
  };

  render() {
    return (

          <div className="contact-container">
              <form
                name="contact"
                method="post"
                action="/contact/thanks/"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={this.handleSubmit}
                autoComplete="off"
              >
                {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
                <input type="hidden" name="form-name" value="contact" />
                <div hidden>
                  <label>
                    Don’t fill this out:{" "}
                    <input name="bot-field" onChange={this.handleChange} />
                  </label>
                </div>
                <div className="holder">
                  <div className="field inputs">
                    <div className="control">
                      <input
                        className="input"
                        type={"text"}
                        name={"name"}
                        onChange={this.handleChange}
                        id={"name"}
                        ref={ input => { this.name = input; }}
                        placeholder="Full Name"
                        autoComplete="off"
                      />
                    </div>
                    <div className="control">
                      <input
                        className="input"
                        type={"email"}
                        name={"email"}
                        onChange={this.handleChange}
                        id={"email"}
                        ref={ input => { this.email = input; }}
                        placeholder="Email Address"
                        autoComplete="off"
                      />
                    </div>
                    <div className="control">
                      <input
                        className="input"
                        type={"number"}
                        name={"phone"}
                        onChange={this.handleChange}
                        id={"phone"}
                        ref={ input => { this.phone = input; }}
                        placeholder="Phone Number"
                        autoComplete="off"
                      />
                    </div>
                    <div className="message">
                    <div className="control">
                      <textarea
                        className="textarea"
                        name={"message"}
                        onChange={this.handleChange}
                        id={"message"}
                        ref={ input => { this.message = input; }}
                        placeholder="Your Message"
                        autoComplete="off"
                      />
                    </div>
                  </div>
                    <button className="main-button-alt is-link" type="submit">
                      Send It
                    </button>
                    { this.state.isValidatedFalse ? 
                    <div className="alert alert-danger" role="alert">
                      <p className="white-text">{ this.state.messageText } </p>
                    </div>
                    : null 
                    }
                  </div>
                </div>
              </form>
            </div>

    );
  }
}
